import "./attendanceVacation.scss";
import React from "react";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import * as U from "../../utils";
// context
import { useAuth } from "../../contexts/auth";
import { useUserList } from "../../contexts/userList";
// api
import { uploadJson, queryJsonArray, deleteRowByNo } from "../../api/auth";
// devextreme
import { alert, confirm } from "devextreme/ui/dialog";
import { Button } from "devextreme-react/button";
import { TextBox } from "devextreme-react/text-box";
import TextArea from "devextreme-react/text-area";
import { SelectBox } from "devextreme-react/select-box";
import DateBox from "devextreme-react/date-box";
import { DataGrid, Column, Selection, LoadPanel, SearchPanel, Export, Summary, TotalItem, Paging } from "devextreme-react/data-grid";
import Scheduler from "devextreme-react/scheduler";
import { Appointment } from "devextreme/ui/scheduler";

export default function AttendanceVacation() {
  const { user } = useAuth();
  const navigate = useNavigate();
  const userListContext = useUserList();

  const [userName, setUserName] = useState<string>("");

  const [startDate, setStartDate] = useState<Date>();
  const [endDate, setEndDate] = useState<Date>();
  const [departmentFilterList, setDepartmentFilterList] = useState<Array<string>>();
  const [departmentFilter, setDepartmentFilter] = useState<string>("");
  const [nameFilterList, setNameFilterList] = useState<Array<string>>();
  const [nameFilter, setNameFilter] = useState<string>("");
  const [isNewButtonVisible, setIsNewButtonVisible] = useState(false);

  const [schedulerDataSource, setSchedulerDataSource] = useState<Array<Object>>();
  const [jsonDataSource, setJsonDataSource] = useState<Array<Object>>();
  const [selectedNo, setSelectedNo] = useState(-1);
  const [selectedRow, setSelectedRow] = useState<Object>();

  // 전역변수는 페이지 로딩 시 useEffect가 2회 반복실행되는 동안만 assign한 값이 유지되고 그 직후 초기화된다.
  let isUseEffectExecuted: boolean = false;

  useEffect(() => {
    // 페이지 새로 고침한 이후 1번만 실행되도록 강제
    if (isUseEffectExecuted) return;
    isUseEffectExecuted = true;

    let acc_admin = 0;
    if (user) {
      acc_admin = user.acc_admin;
      setUserName(user.name);
    }
    // (admin) [0] attendance W, [1] Query, [2] E & D,
    if (U.bitAt(acc_admin, 2)) setIsNewButtonVisible(true);
    setStartDate(new Date());
    setEndDate(new Date());

    initializeQueryList();
    search();
  }, []);

  function initializeQueryList() {
    // userListContext 정보를 분석해서 selectBox의 item list를 초기화한다.
    let l1: Array<string> = [""];
    let l2: Array<string> = [""];
    for (let i = 0; i < userListContext.names.length; i++) {
      // acc_admin을 검사해서 근태관리 대상인 user에 대해서만 처리한다.
      if (!U.bitAt(userListContext.acc_admins[i], 0)) continue;
      U.addStringArrayIfNotExist(l1, userListContext.departments[i]);
      U.addStringArrayIfNotExist(l2, userListContext.names[i]);
    }
    setDepartmentFilterList(l1);
    setNameFilterList(l2);
  }

  function onDepartmentFilterSelectionChanged(e: any) {
    let str: string = e.selectedItem;
    setDepartmentFilter(str);
  }

  function onNameFilterSelectionChanged(e: any) {
    let str: string = e.selectedItem;
    setNameFilter(str);
  }

  async function onSearchButtonClicked() {
    search();
  }

  function searchSchedulerData() {
    let newJsonData = new Array<Object>();

    let oneRowJson: Object | null = new Object();
    oneRowJson = U.addStringToJson(oneRowJson, "text", "test1");
    oneRowJson = U.addBooleanToJson(oneRowJson, "allDay", true);
    oneRowJson = U.addDateToJson(oneRowJson, "startDate", new Date("2025-01-20"));
    oneRowJson = U.addStringToJson(oneRowJson, "description", "desc1");
    if (oneRowJson !== null) newJsonData.push(oneRowJson);

    oneRowJson = new Object();
    oneRowJson = U.addStringToJson(oneRowJson, "text", "test2");
    oneRowJson = U.addBooleanToJson(oneRowJson, "allDay", true);
    oneRowJson = U.addDateToJson(oneRowJson, "startDate", new Date("2025-01-21"));
    //oneRowJson = U.addDateToJson(oneRowJson, "endDate", new Date("2025-01-22"));
    oneRowJson = U.addStringToJson(oneRowJson, "description", "desc2");
    if (oneRowJson !== null) newJsonData.push(oneRowJson);

    setSchedulerDataSource(newJsonData);
  }

  function search() {
    // 전체 날짜의 vacation data를 받아온다.
    // attendancevacation table에는 department와 name column이 없다.
    // 따라서 모든 user에 대해 data를 받아온 후 userList의 id와 비교해서 filtering 처리한다.
    if (!userListContext.ids) {
      alert("No user list!", "Error");
      return;
    }
    let query = "SELECT * FROM attendancevacation";

    //query += ` WHERE department = ${eightDigitStringDateFrom} AND date <= ${eightDigitStringDateTo}`;
    //query += ` WHERE date >= ${eightDigitStringDateFrom} AND date <= ${eightDigitStringDateTo}`;
    query += " ORDER BY date Desc, id Asc";
    queryJsonArray("/attendance/query", query).then(
      (jsonData: Array<Object>) => {
        let newJsonData = new Array<Object>();
        for (let i = 0; i < jsonData.length; i++) {
          let oneRowJson: Object | null = jsonData[i];
          let date1: number = U.intValueFromJson(oneRowJson, "date");
          let userId: string = U.stringValueFromJson(oneRowJson, "id");
          let userName: string = U.getStringValueFromStringArray(userId, userListContext.ids, userListContext.names);
          if (userName.length == 0) continue;
          let userDepartment: string = U.getStringValueFromStringArray(userId, userListContext.ids, userListContext.departments);
          if (departmentFilter) {
            if (departmentFilter.length > 0) {
              if (departmentFilter !== userDepartment) continue;
            }
          }
          if (nameFilter) {
            if (nameFilter.length > 0) {
              if (nameFilter !== userName) continue;
            }
          }

          // data for DataGrid
          oneRowJson = U.addStringToJson(oneRowJson, "date2", U.stringDateFromEightDigitIntDate(date1));
          if (oneRowJson === null) continue;
          oneRowJson = U.addStringToJson(oneRowJson, "userName", userName);
          if (oneRowJson === null) continue;

          // data for Scheduler
          let substitute: string = U.stringValueFromJson(oneRowJson, "substitute");
          let type: string = U.stringValueFromJson(oneRowJson, "type");
          let type2: string = "";
          if (type.indexOf("연차") === 0) type2 = "연차";
          if (type.indexOf("반차") === 0) type2 = "반차";
          if (type.indexOf("출장") === 0) type2 = "출장";
          if (type.indexOf("휴가") >= 0) type2 = "휴가"; // 경조휴가, 보건휴가가
          oneRowJson = U.addStringToJson(oneRowJson, "text", `${userName} (${type2})`);
          oneRowJson = U.addBooleanToJson(oneRowJson, "allDay", true);
          oneRowJson = U.addDateFromEightDigitIntToJson(oneRowJson, "startDate", date1);
          oneRowJson = U.addStringToJson(oneRowJson, "description", `${type} / 업무대체자: ${substitute}`);
          if (oneRowJson === null) continue;

          newJsonData.push(oneRowJson);
        }
        setJsonDataSource(newJsonData);
        setSchedulerDataSource(newJsonData);
      } // End of queryJsonArray.then(() => {}
    );
  }

  async function onNewButtonClicked() {
    navigate("/attendance/vacation/new");
  }
  async function onDeleteButtonClicked() {
    if (!selectedRow) {
      alert("Select row first!", "Error");
      return;
    }
    let userName: string = U.stringValueFromJson(selectedRow, "userName");
    let date2: string = U.stringValueFromJson(selectedRow, "date2");
    let res = confirm(`${userName}-${date2}`, "Delete?");
    res.then((dialogResult) => {
      if (dialogResult) {
        // Yes
        deleteRowByNo("/attendance/deletevacation", selectedNo).then(() => {
          setSelectedNo(-1);
          search();
        });
      } else {
        // No
      }
    });
  }

  function onDataGridSelectionChanged(e: any) {
    setSelectedNo(e.selectedRowsData[0].no);
    setSelectedRow(e.selectedRowsData[0]);
  }

  return (
    <React.Fragment>
      <h2 className={"content-block"}>연차/출장관리</h2>
      <div className={"content-block"}>
        <div className={"dx-card responsive-paddings"}>
          <div className={"flex-containerH"}>
            <Scheduler timeZone="Asia/Seoul" dataSource={schedulerDataSource} defaultCurrentView="month" height={730} editing={false} views={["month"]} />
          </div>

          <div className={"flex-containerH"} />
          <div className={"flex-containerH"} />
          <div className={"flex-containerH"} />

          <div className={"flex-containerH"}>
            <div className={"flex-item1"}>
              <SelectBox label="부서" dataSource={departmentFilterList} width={180} height={40} onSelectionChanged={onDepartmentFilterSelectionChanged} />
            </div>

            <div className={"flex-item1"}>
              <SelectBox label="이름" dataSource={nameFilterList} width={180} height={40} onSelectionChanged={onNameFilterSelectionChanged} />
            </div>

            <div className={"flex-item1"}>
              <Button text="New" onClick={onNewButtonClicked} visible={isNewButtonVisible} />
            </div>
            <div className={"flex-item1"}>
              <Button text="Delete" onClick={onDeleteButtonClicked} visible={isNewButtonVisible} />
            </div>

            <div className={"flex-item1"}>
              <Button text="Search" onClick={onSearchButtonClicked} type="default" icon="download" />
            </div>
          </div>

          <div>
            <DataGrid dataSource={jsonDataSource} onSelectionChanged={onDataGridSelectionChanged} columnAutoWidth={true} allowColumnReordering={false}>
              <Paging defaultPageSize={20} />
              <LoadPanel enabled />
              <Selection mode="single" />
              <Column dataField="date2" caption="일자" />
              <Column dataField="userName" caption="이름" />
              <Column dataField="type" caption="Type" />
              <Column dataField="substitute" caption="업무대체자" />
              <SearchPanel visible={true} width={300} placeholder={"Find..."} />
              <Export enabled={true} allowExportSelectedData={false} />
            </DataGrid>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
}

/*const schedulerData: Appointment[] = [
  {
    text: "편규동 (연차)",
    startDate: new Date("2025-01-20"),
    endDate: new Date("2025-01-21"),
    allDay: true,
    description: "대체근무자: 신철균",
  },
  {
    text: "Book Flights to San Fran for Sales Trip",
    startDate: new Date("2025-01-20T19:00:00.000Z"),
    endDate: new Date("2025-01-20T20:00:00.000Z"),
    allDay: true,
  },
  {
    text: "Install New Router in Dev Room",
    startDate: new Date("2025-01-20T21:30:00.000Z"),
    endDate: new Date("2025-01-20T22:30:00.000Z"),
  },

  {
    text: "Approve Personal Computer Upgrade Plan",
    startDate: new Date("2025-01-21T17:00:00.000Z"),
    endDate: new Date("2025-01-22T18:00:00.000Z"),
  },
];
*/
