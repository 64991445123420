import "./attendanceVacationNew.scss";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import * as U from "../../utils";
// context
import { useAuth } from "../../contexts/auth";
import { useUserList } from "../../contexts/userList";
// api
import { uploadJson, uploadJsonAndAlert } from "../../api/auth";
// devextreme
import { alert, confirm } from "devextreme/ui/dialog";
import { Button } from "devextreme-react/button";
import { TextBox } from "devextreme-react/text-box";
import DateBox from "devextreme-react/date-box";
import { SelectBox } from "devextreme-react/select-box";

export default function AttendanceVacationNew() {
  const { user } = useAuth();
  const navigate = useNavigate();
  const userListContext = useUserList();

  const [name, setName] = useState("");
  const [nameList, setNameList] = useState<Array<string>>();
  const [date, setDate] = useState<Date>();
  const [type, setType] = useState("");
  const [substitute, setSubstitute] = useState("");

  // 전역변수는 페이지 로딩 시 useEffect가 2회 반복실행되는 동안만 assign한 값이 유지되고 그 직후 초기화된다.
  let isUseEffectExecuted: boolean = false;

  useEffect(() => {
    // 페이지 새로 고침한 이후 1번만 실행되도록 강제
    if (isUseEffectExecuted) return;
    isUseEffectExecuted = true;
    setDate(new Date());
    initializeQueryList();
  }, []);

  function initializeQueryList() {
    // userListContext 정보를 분석해서 selectBox의 item list를 초기화한다.
    let l1: Array<string> = [""];
    for (let i = 0; i < userListContext.names.length; i++) {
      // acc_admin을 검사해서 근태관리 대상인 user에 대해서만 처리한다.
      if (!U.bitAt(userListContext.acc_admins[i], 0)) continue;
      U.addStringArrayIfNotExist(l1, userListContext.names[i]);
    }
    setNameList(l1);
  }

  function onNameSelectionChanged(e: any) {
    let str: string = e.selectedItem;
    setName(str);
  }
  function onDateDateBoxValueChanged(e: Date) {
    setDate(e);
  }
  function onTypeSelectionChanged(e: any) {
    let str: string = e.selectedItem;
    setType(str);
  }
  function onSubstituteTextBoxValueChanged(e: string) {
    setSubstitute(e);
  }

  ///////////////////////////// Upload Button
  async function onUploadButtonClicked() {
    if (name.length === 0) {
      alert("직원 이름을 선택하세요!", "Error");
      return;
    }
    let id: string = U.getStringValueFromStringArray(name, userListContext.names, userListContext.ids);
    if (type.length === 0) {
      alert("Type을 선택하세요!", "Error");
      return;
    }
    // DateBox를 선택하지 않으면 초기값으로 undefined로 표시됨.
    if (!date) {
      alert("일자를 입력하세요!", "Error");
      return;
    }
    // int or float인 column 들에 대해 string에서 int or float으로 변환 후 upload
    let dateN: number = 0;
    if (date) dateN = U.eightDigitIntDateFromDate(date);

    let obj: Object = {
      id: id,
      date: dateN,
      type: type,
      substitute: substitute,
    };

    await uploadJson("/attendance/uploadvacation", obj).then((response_status: number) => {
      if (response_status === 0) {
        alert("Upload succeeded!", "Success");
        navigate(-1);
        return;
      }
      if (response_status === 22) {
        // 22: Same id exists when upload new item
        alert("같은 날짜의 연차/출장이 이미 존재합니다!", "Error");
        return;
      } else {
        alert("Failed to upload!", "Error");
        return;
      }
    });
  }

  return (
    <React.Fragment>
      <h2 className={"content-block"}>신규 연차/출장</h2>
      <div className={"content-block"}>
        <div className={"dx-card responsive-paddings"}>
          <div className={"flex-containerH"}>
            <div className={"flex-item2"}>
              <SelectBox label="이름" dataSource={nameList} width={300} onSelectionChanged={onNameSelectionChanged} />
            </div>

            <div className={"flex-item2"}>
              <DateBox label="일자" defaultValue={new Date()} valueChangeEvent="change" onValueChange={onDateDateBoxValueChanged} type="date" width={300} />
            </div>

            <div className={"flex-item2"}>
              <SelectBox
                label="Type"
                dataSource={["연차", "경조휴가", "보건휴가", "반차", "출장", "연차 (예비군 등/차감X)", "반차 (생일자 등/차감X)"]}
                width={300}
                onSelectionChanged={onTypeSelectionChanged}
              />
            </div>

            <div className={"flex-item2"}>
              <TextBox
                label="업무대체자"
                value={substitute}
                valueChangeEvent="keyup"
                onValueChange={onSubstituteTextBoxValueChanged}
                width={300}
                showClearButton={true}
              />
            </div>
          </div>

          <div>
            <div className={"flex-containerV"}>
              <div className={"flex-item1"}>
                <Button text="Upload" onClick={onUploadButtonClicked} width={300} type="default" icon="upload" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
}
