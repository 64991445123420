import "./attendance.scss";
import React from "react";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import * as U from "../../utils";
// context
import { useAuth } from "../../contexts/auth";
import { useUserList } from "../../contexts/userList";
// api
import { uploadJson, queryJsonArray } from "../../api/auth";
// devextreme
import { alert, confirm } from "devextreme/ui/dialog";
import { Button } from "devextreme-react/button";
import { TextBox } from "devextreme-react/text-box";
import TextArea from "devextreme-react/text-area";
import { SelectBox } from "devextreme-react/select-box";
import DateBox from "devextreme-react/date-box";
import { DataGrid, Column, Selection, LoadPanel, SearchPanel, Export, Summary, TotalItem, Paging } from "devextreme-react/data-grid";

export default function Attendance() {
  const { user } = useAuth();
  const navigate = useNavigate();
  const userListContext = useUserList();

  const [userName, setUserName] = useState<string>("");

  const [startDate, setStartDate] = useState<Date>();
  const [endDate, setEndDate] = useState<Date>();
  const [departmentFilterList, setDepartmentFilterList] = useState<Array<string>>();
  const [departmentFilter, setDepartmentFilter] = useState<string>("");
  const [nameFilterList, setNameFilterList] = useState<Array<string>>();
  const [nameFilter, setNameFilter] = useState<string>("");

  const [jsonDataSource, setJsonDataSource] = useState<Array<Object>>();

  // 전역변수는 페이지 로딩 시 useEffect가 2회 반복실행되는 동안만 assign한 값이 유지되고 그 직후 초기화된다.
  let isUseEffectExecuted: boolean = false;

  useEffect(() => {
    // 페이지 새로 고침한 이후 1번만 실행되도록 강제
    if (isUseEffectExecuted) return;
    isUseEffectExecuted = true;

    let acc_admin = 0;
    if (user) {
      acc_admin = user.acc_admin;
      setUserName(user.name);
    }
    setStartDate(new Date());
    setEndDate(new Date());

    // userList가 이미 다운로드 받은 경우임
    initializeQueryList();
  }, []);

  function initializeQueryList() {
    // userListContext 정보를 분석해서 selectBox의 item list를 초기화한다.
    let l1: Array<string> = [""];
    let l2: Array<string> = [""];
    for (let i = 0; i < userListContext.names.length; i++) {
      // acc_admin을 검사해서 근태관리 대상인 user에 대해서만 처리한다.
      if (!U.bitAt(userListContext.acc_admins[i], 0)) continue;
      U.addStringArrayIfNotExist(l1, userListContext.departments[i]);
      U.addStringArrayIfNotExist(l2, userListContext.names[i]);
    }
    setDepartmentFilterList(l1);
    setNameFilterList(l2);
  }

  function calculateAllAttendance() {
    if (!userListContext.ids) {
      alert("No user list!", "Error");
      return;
    }
    let dateToSearchFrom: Date = new Date(Date.now());
    if (startDate) dateToSearchFrom = startDate;
    let dateToSearchTo: Date = new Date(Date.now());
    if (endDate) dateToSearchTo = endDate;
    if (dateToSearchFrom > dateToSearchTo) {
      alert("Select proper date range!", "Error");
      return;
    }

    let result1 = U.intArrayFromDate(dateToSearchFrom);
    let startYear = result1[0];
    let startMonth = result1[1];
    let startDay = result1[2];
    result1 = U.intArrayFromDate(dateToSearchTo);
    let endYear = result1[0];
    let endMonth = result1[1];
    let endDay = result1[2];

    let startStringDate = U.startUTCStringDate(dateToSearchFrom);
    let endStringDate = U.endUTCStringDate(dateToSearchTo);
    let query = "SELECT * FROM attendance";
    query += ` WHERE time >= '${startStringDate}' AND time < '${endStringDate}'`;
    query += " ORDER BY time Asc";
    queryJsonArray("/attendance/query", query).then(
      (attendanceJson: Array<Object>) => {
        let eightDigitStringDateFrom: number = U.eightDigitIntDateFromDate(dateToSearchFrom);
        let eightDigitStringDateTo: number = U.eightDigitIntDateFromDate(dateToSearchTo);
        query = "SELECT * FROM attendancevacation";
        query += ` WHERE date >= ${eightDigitStringDateFrom} AND date <= ${eightDigitStringDateTo}`;
        query += " ORDER BY date Asc, id Asc";
        queryJsonArray("/attendance/query", query).then((vacationJson: Array<Object>) => {
          let newJsonArray = new Array<Object>();

          // user 한 명씩에 대해 loop
          for (let i = 0; i < userListContext.ids.length; i++) {
            let user_id = userListContext.ids[i];
            let user_name = userListContext.names[i];
            let user_department = userListContext.departments[i];
            let user_acc_admin = userListContext.acc_admins[i];
            // 집계하지 않는 경우를 체크한다.
            if (!U.bitAt(user_acc_admin, 0)) continue;
            if (departmentFilter) {
              if (departmentFilter !== user_department) continue;
            }
            if (nameFilter) {
              if (nameFilter !== user_name) continue;
            }

            let row1Json: Object | null = new Object();
            row1Json = U.addStringToJson(row1Json, "부서", user_department);
            row1Json = U.addStringToJson(row1Json, "이름", user_name);
            row1Json = U.addStringToJson(row1Json, "타입", "출근시각");

            let row2Json: Object | null = new Object();
            row2Json = U.addStringToJson(row2Json, "부서", user_department);
            row2Json = U.addStringToJson(row2Json, "이름", user_name);
            row2Json = U.addStringToJson(row2Json, "타입", "퇴근시각");

            let row3Json: Object | null = new Object();
            row3Json = U.addStringToJson(row3Json, "부서", user_department);
            row3Json = U.addStringToJson(row3Json, "이름", user_name);
            row3Json = U.addStringToJson(row3Json, "타입", "초과근무");

            let moveD: number = 0;

            // 날짜범위에 대해 하루씩 loop
            while (true) {
              let movedResult = U.findMovedDate(startYear, startMonth, startDay, 0, 0, moveD);
              let year = movedResult[0];
              let month = movedResult[1];
              let day = movedResult[2];

              if (year > endYear) break;
              if (year === endYear) {
                if (month > endMonth) break;
                if (month === endMonth) {
                  if (day > endDay) break;
                }
              }

              let currentStartDate: Date = U.startLocalDateFromIntArray(year, month, day);
              let currentEndDate: Date = U.endLocalDateFromIntArray(year, month, day);
              let currentEigitDigitIntDate: number = U.eightDigitIntDateFromIntArray(year, month, day);

              let dayString = U.dayString(currentStartDate);
              let dateHeader: string = `${month}/${day}(${dayString})`; // "4/1(수)"

              // vacationJson[]에서의 연차 여부를 검색한다.
              let isAllDayOff: boolean = false;
              let isHalfDayOff: boolean = false;
              let vacationType: string = "";
              for (let j = 0; j < vacationJson.length; j++) {
                let vac_id: string = U.stringValueFromJson(vacationJson[j], "id");
                let vac_date: number = U.intValueFromJson(vacationJson[j], "date");
                let vac_type: string = U.stringValueFromJson(vacationJson[j], "type");
                if (user_id !== vac_id) continue;
                if (currentEigitDigitIntDate !== vac_date) continue;
                if (vac_type.indexOf("반차") === 0) isHalfDayOff = true;
                else isAllDayOff = true;
                vacationType = vac_type;
                break;
              }

              let user_startTime: string = ""; // 00:00
              let user_endTime: string = "";
              let user_startDate: Date | undefined;
              let user_endDate: Date | undefined;
              let overTime: string = "";

              if (isAllDayOff) {
                if (vacationType.indexOf("연차") === 0) {
                  // "연차..." 의 경우에는 "연차" 로만 짧게 표시한다.
                  user_startTime = "연차";
                  user_endTime = "연차";
                } else {
                  user_startTime = vacationType;
                  user_endTime = vacationType;
                }
                overTime = "00:00";
              } else {
                // attendanceJson[]에서의 상태를 검색한다.
                for (let j = 0; j < attendanceJson.length; j++) {
                  let att_id: string = U.stringValueFromJson(attendanceJson[j], "id");
                  if (user_id !== att_id) continue;
                  let att_type: string = U.stringValueFromJson(attendanceJson[j], "type");
                  // "출근: 분당 510호", "출근: 외근", "퇴근: 분당 510호", "퇴근: 외근"
                  let att_time: Date = U.dateValueFromJson(attendanceJson[j], "time");
                  if (att_time < currentStartDate) continue;
                  if (currentEndDate < att_time) continue;

                  if (att_type.length >= 2) {
                    if (att_type[0] === "출" && att_type[1] === "근") {
                      user_startDate = att_time;
                      user_startTime = U.fourDigitClockStringFromDate(att_time);
                      user_endTime = "";
                    }
                    if (att_type[0] === "퇴" && att_type[1] === "근") {
                      user_endDate = att_time;
                      user_endTime = U.fourDigitClockStringFromDate(att_time);
                    }
                  }
                } // End of for loop (attendanceJson)

                // overTime 계산
                if (user_startDate !== undefined && user_endDate !== undefined) {
                  let refTime_s = 9 * 3600;
                  if (isHalfDayOff) refTime_s = 4 * 3600; // 반차는 기준 근로시간이 4시간
                  let s = Math.round((user_endDate.getTime() - user_startDate.getTime()) / 1000);
                  if (s >= refTime_s) {
                    // 기준시간 이상인 경우
                    let s2 = s - refTime_s;
                    let h = Math.floor(s2 / 3600);
                    let s3 = s2 - h * 3600;
                    let m = Math.round(s3 / 60);
                    let hh: string = `${h}`;
                    if (hh.length < 2) hh = "0" + hh;
                    let mm: string = `${m}`;
                    if (mm.length < 2) mm = "0" + mm;
                    overTime = `${hh}:${mm}`;
                  } else {
                    // 기준시간 이하인 경우
                    let s2 = Math.abs(s - refTime_s);
                    let h = Math.floor(s2 / 3600);
                    let s3 = s2 - h * 3600;
                    let m = Math.round(s3 / 60);
                    let hh: string = `${h}`;
                    if (hh.length < 2) hh = "0" + hh;
                    let mm: string = `${m}`;
                    if (mm.length < 2) mm = "0" + mm;
                    overTime = `-${hh}:${mm}`;
                  }
                }
              }

              if (isHalfDayOff) user_endTime += " (반차)";

              row1Json = U.addStringToJson(row1Json, dateHeader, user_startTime);
              row2Json = U.addStringToJson(row2Json, dateHeader, user_endTime);
              row3Json = U.addStringToJson(row3Json, dateHeader, overTime);

              moveD++;
            } // End of while loop (날짜)

            if (row1Json !== null) newJsonArray.push(row1Json);
            if (row2Json !== null) newJsonArray.push(row2Json);
            if (row3Json !== null) newJsonArray.push(row3Json);
          } // End of for loop (userJson)
          setJsonDataSource(newJsonArray);
        }); // End of attendancevacation queryJsonArray.then(() => {}
      } // End of attendance queryJsonArray.then(() => {}
    );
  }
  function onFromDateBoxValueChanged(e: Date) {
    setStartDate(e);
  }
  function onToDateBoxValueChanged(e: Date) {
    setEndDate(e);
  }

  function onDepartmentFilterSelectionChanged(e: any) {
    let str: string = e.selectedItem;
    setDepartmentFilter(str);
  }

  function onNameFilterSelectionChanged(e: any) {
    let str: string = e.selectedItem;
    setNameFilter(str);
  }

  async function onSearchButtonClicked() {
    calculateAllAttendance();
  }

  const onCellPrepared = (e: any) => {
    if (e.rowType !== "data") return;

    // 초과근무는 row 전체를 배경색 회색으로 표시
    if (U.stringValueFromJson(e.data, "타입") === "초과근무") {
      e.cellElement.style.cssText = "background-color: #EEEEEE";

      // 초과근무시간이 음수이면 red로 표시
      if (e.column.dataField !== "부서" && e.column.dataField !== "이름" && e.column.dataField !== "타입") {
        // e.column.dataField: "4/28(금)"
        let value: string = U.stringValueFromJson(e.data, e.column.dataField);
        if (value.length > 0) {
          if (value[0] === "-") e.cellElement.style.cssText = "background-color: #EEEEEE; color: red";
        }
      }
    }
  };

  return (
    <React.Fragment>
      <h2 className={"content-block"}>출퇴근 관리</h2>
      <div className={"content-block"}>
        <div className={"dx-card responsive-paddings"}>
          <div className={"flex-containerH"}>
            <div className={"flex-item1"}>
              <DateBox
                label="From"
                defaultValue={new Date()}
                valueChangeEvent="change"
                onValueChange={onFromDateBoxValueChanged}
                type="date"
                width={180}
                height={40}
              />
            </div>

            <div className={"flex-item1"}>
              <DateBox
                label="To"
                defaultValue={new Date()}
                valueChangeEvent="change"
                onValueChange={onToDateBoxValueChanged}
                type="date"
                width={180}
                height={40}
              />
            </div>

            <div className={"flex-item1"}>
              <SelectBox label="부서" dataSource={departmentFilterList} width={180} height={40} onSelectionChanged={onDepartmentFilterSelectionChanged} />
            </div>

            <div className={"flex-item1"}>
              <SelectBox label="이름" dataSource={nameFilterList} width={180} height={40} onSelectionChanged={onNameFilterSelectionChanged} />
            </div>

            <div className={"flex-item1"}>
              <Button text="Search" onClick={onSearchButtonClicked} type="default" icon="download" />
            </div>
          </div>

          <div>
            <DataGrid dataSource={jsonDataSource} columnAutoWidth={true} allowColumnReordering={false} onCellPrepared={onCellPrepared}>
              <Paging defaultPageSize={21} />
              <LoadPanel enabled />
              <Selection mode="single" />
              <SearchPanel visible={true} width={300} placeholder={"Find..."} />
              <Export enabled={true} allowExportSelectedData={false} />
            </DataGrid>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
}
